import { uuidv4 } from "@firebase/util";
import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Category } from "../types";
import { db } from "./firebase";
import slugify from "slugify";

type GetAllSuscriptionParams = {
  onDataChange: (categories: Category[]) => void;
};

const getAllSuscription = ({ onDataChange }: GetAllSuscriptionParams) => {
  const q = query(
    collection(db, "categories") as CollectionReference<Category>,
    orderBy("name")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const categories: Category[] = [];

    snapshot.forEach((doc) => {
      categories.push(doc.data());
    });

    onDataChange(categories);
  });

  return unsubscribe;
};

const getById = async (categoryId: string) => {
  const docRef = doc(db, "categories", categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as Category;
  } else {
    throw new Error("category-not-found");
  }
};

type CategoryCreateParams = {
  active: boolean;
  coverUrl: string;
  isFeatured: boolean;
  name: string;
};

const create = (params: CategoryCreateParams) => {
  const categoryId = uuidv4();
  const categoryRef = doc(db, "categories", categoryId);

  return setDoc(categoryRef, {
    ...params,
    id: categoryId,
    backgroundColor: "#000",
    iconUrl: "",
    slug: slugify(params.name, { lower: true }),
  });
};

type CategoryUpdateParams = {
  active?: boolean;
  coverUrl?: string;
  isFeatured?: boolean;
  name?: string;
};

const updateCategory = (categoryId: string, params: CategoryUpdateParams) => {
  const categoryRef = doc(db, "categories", categoryId);

  return updateDoc(categoryRef, {
    ...params,
    ...(params.name && {
      slug: slugify(params.name, { lower: true }),
    }),
  });
};

export const CategoriesService = {
  getAllSuscription,
  getById,
  create,
  updateCategory,
};
