import {
  CollectionReference,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./firebase";
import { ChatMessage } from "../types";

type GetAllSuscriptionParams = {
  chatId: string;
  onDataChange: (categories: ChatMessage[]) => void;
};

const getAllSuscription = ({
  chatId,
  onDataChange,
}: GetAllSuscriptionParams) => {
  const q = query(
    collection(
      db,
      `messages/${chatId}/messages`
    ) as CollectionReference<ChatMessage>,
    orderBy("createdAt")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const chatMessages: ChatMessage[] = [];

    snapshot.forEach((doc) => {
      chatMessages.push(doc.data());
    });

    onDataChange(chatMessages);
  });

  return unsubscribe;
};

export const ChatMessageService = {
  getAllSuscription,
};
