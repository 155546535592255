import { AIQuestionData, AIQuestionResponse } from '../types/assistantResponse.type';

export async function generateQuestions(question: string): Promise<AIQuestionData> {
  try {
    const url = `${process.env.REACT_APP_AI_FEATURE_ENDPOINT}/api/v2/question-generate`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ question }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result: AIQuestionResponse = await response.json();  // Cast the result to the AIQuestionResponse type

    return result.data;  // Return the data containing the questions and other fields
  } catch (error) {
    console.error('Error in generateQuestions:', error);
    throw error;
  }
}

