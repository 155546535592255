
import { Route } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { AssistantDemo } from "../pages/ai/AiIndexPage/AiIndexPage";

export const AiRoutes = (
  <>
    <Route
      path={routeNames["ai.assistant"]}
      element={
        <ProtectedRoute>
          <AssistantDemo
            title="Assistant Demo"
          />
        </ProtectedRoute>
      }
    />
  </>
);
