import QuestionGeneratorDemo from "../../../components/ai/Demos/FollowUpQuestionTest";
import { PageHeader } from "../../../components/shared";

type AssistantDemoProps = {
  title: string;
};

export const AssistantDemo = ({
  title,
}: AssistantDemoProps) => {
  return (
    <>
      <PageHeader>{title}</PageHeader>
      <div className="mt-8">
        <QuestionGeneratorDemo /> 
      </div>
    </>
  );
};
