import React, { useState } from "react";
import { generateQuestions } from "../../../services"; // Adjust the import path if needed
import { AIQuestionData } from "../../../types"; // Adjust the import path if needed

const QuestionGeneratorDemo: React.FC = () => {
  const [questionData, setQuestionData] = useState<AIQuestionData | null>(null); // Holds the generated questions data
  const [inputText, setInputText] = useState<string>(""); // Holds the user's input
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [error, setError] = useState<string | null>(null); // Error handling
  const [answers, setAnswers] = useState<{ [key: string]: string }>({}); // Holds the selected answers

  const handleGenerateQuestion = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setLoading(true); // Start loading
    setError(null); // Reset error state

    try {
      const response = await generateQuestions(inputText); // Send input to the API
      setQuestionData(response); // Set the full question data response
      setAnswers({}); // Reset selected answers when new questions are generated
    } catch (err) {
      console.error("Error generating question:", err);
      setError("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleAnswerSelect = (questionKey: string, answer: string) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionKey]: answer,
    }));
  };

  const handleSubmitAnswers = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent default form submission behavior
    console.log("Submitted Answers:", answers); // Log the selected answers to the console
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        Tell Us About the Problem
      </h2>

      <form onSubmit={handleGenerateQuestion}>
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="What's the issue you need help with?"
          className="w-full p-3 border rounded-lg mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          type="submit"
          className={`w-full bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 transition ${
            loading ? 'cursor-not-allowed' : ''
          }`}
          disabled={loading}
        >
          {loading ? "Finding Questions..." : "Next"}
        </button>
      </form>

      {error && <p className="text-red-500 mt-4">{error}</p>}

      {questionData && questionData.isGood === false ? (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Assistant:</h3>
          <p className="bg-gray-100 p-4 rounded-lg">{questionData.originalResponse.answer}</p>
        </div>
      ) : (
        questionData && (
          <form onSubmit={handleSubmitAnswers} className="mt-6">
            <h3 className="text-xl font-semibold mb-2">We just need a little more info:</h3>

            {Object.keys(questionData).map((questionKey, index) => {
              const question = questionData[questionKey as keyof AIQuestionData];

              // Ensure that the key corresponds to a question and is of type QuestionResponse
              if (question && typeof question === 'object' && 'question' in question && 'answers' in question && question.question) {
                return (
                  <div key={index} className="bg-gray-100 p-4 mb-4 rounded-lg">
                    <p className="font-semibold">{question.question}</p>
                    <div className="mt-2">
                      {question.answers.map((answer: string, answerIndex: number) => (
                        <button
                          key={answerIndex}
                          type="button"
                          className={`mr-2 mb-2 py-2 px-4 rounded-lg focus:outline-none ${
                            answers[questionKey] === answer
                              ? "bg-blue-500 text-white"
                              : "bg-gray-200 text-black"
                          }`}
                          onClick={() => handleAnswerSelect(questionKey, answer)}
                        >
                          {answer}
                        </button>
                      ))}
                    </div>
                  </div>
                );
              }

              return null; // Skip non-question fields like `isGood` and `originalResponse`
            })}

            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mt-4"
              disabled={Object.keys(answers).length === 0} // Disable if no questions were answered
            >
              Send Your Answers
            </button>
          </form>
        )
      )}
    </div>
  );
};

export default QuestionGeneratorDemo;

