import { useEffect, useState } from "react";
import { PostService } from "../../../services";
import { ProfileService } from "../../../services/profile.service";
import { Chat, Post, Profile, ServiceRequest } from "../../../types";
import { Modal } from "../../shared/Modal/Modal";
import useToggle from "../../../hooks/useToggle";
import { BiChat } from "react-icons/bi";
import { ChatService } from "../../../services/chat.service";
import { ChatMessagesList } from "../../lists/ChatMessagesList";
import { DismissedChatIndicator } from "../../chats/DismissedChatIndicator";

type ServiceRequestListItemParams = {
  serviceRequest: ServiceRequest;
};

export const ServiceRequestListItem = ({
  serviceRequest,
}: ServiceRequestListItemParams) => {
  const [post, setPost] = useState<Post>();
  const [profile, setProfile] = useState<Profile>();
  const [chat, setChat] = useState<Chat>();
  const [showModal, setShowModal] = useToggle(false);

  useEffect(() => {
    const getPostAndProfile = async () => {
      if (serviceRequest) {
        PostService.getById(serviceRequest.postId).then((post) =>
          setPost(post)
        );

        ProfileService.getById(serviceRequest.userId).then((profile) =>
          setProfile(profile)
        );

        if (serviceRequest.status === "accepted") {
          ChatService.get({
            knockId: serviceRequest.knockId,
            providerId: serviceRequest.userId,
          }).then((chat) => setChat(chat));
        }
      }
    };

    getPostAndProfile();
  }, [serviceRequest]);

  if (post === undefined || profile === undefined) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }

  const statusClass = (status: string) => {
    switch (status) {
      case "accepted":
        return "badge-success";
      case "rejected":
        return "badge-error";
    }
  };

  const name = `${profile.name} ${profile.lastName}`;

  return (
    <>
      <Modal
        show={showModal}
        onCloseModal={() => setShowModal.set(false)}
        title="Chat messages"
      >
        {chat && <ChatMessagesList chat={chat!} />}
      </Modal>
      <tr className="flex flex-col lg:table-row">
        <td>
          <div className="font-bold">
            {post.businessName}
          </div>
          <div className="font-normal">
            {name}
          </div>
          <div className="font-normal text-xs lg:hidden">
            <div>{profile.email}</div>
            <div>{profile.phoneNumber}</div>
            <div className="mt-2 flex items-center">
              <span className={`badge ${statusClass(serviceRequest.status)}`}>
                {serviceRequest.status}
              </span>

              {serviceRequest.status === "accepted" && (
                <button
                  className="ml-2 btn btn-xs"
                  onClick={() => setShowModal.toggle()}
                >
                  <BiChat className="cursor-pointer mr-1" />
                  Chat
                </button>
              )}
            </div>
          </div>
        </td>
        <td className="hidden lg:table-cell">
          <p>{profile.email}</p>
          <p>{profile.phoneNumber}</p>
          </td>
        <td className="hidden lg:table-cell">
          <span className={`badge ${statusClass(serviceRequest.status)}`}>
            {serviceRequest.status}
          </span>
        </td>
        <td className="hidden lg:table-cell">
          {serviceRequest.status === "accepted" && (
            <div className="flex items-center gap-2">
            <span className="cursor-pointer bg-info p-1 rounded-full text-white">
              <BiChat
                className="cursor-pointer"
                onClick={() => setShowModal.toggle()}
              />
            </span>
            {
              chat?.isDismissed && <DismissedChatIndicator />
            }
            </div>
          )}
        </td>
      </tr>
    </>
  );
};
