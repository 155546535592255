import {
  CollectionReference,
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
  orderBy,
} from "firebase/firestore";
import { Post } from "../types/post.type";
import { db } from "./firebase";

type GetAllSuscriptionParams = {
  onDataChange: (posts: Post[]) => void;
  status: "pending" | "approved" | "rejected" | "blocked";
};

const getAllSuscription = ({
  status,
  onDataChange,
}: GetAllSuscriptionParams) => {
  const q = query(
    collection(db, "posts") as CollectionReference<Post>,
    where("status", "==", status),
    orderBy("businessName", "asc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const posts: Post[] = [];

    snapshot.forEach((doc) => {
      const postData = doc.data();
      posts.push({ ...postData, createdAt: new Date(postData.createdAt) });
    });

    onDataChange(posts);
  });

  return unsubscribe;
};

const getById = async (categoryId: string) => {
  const docRef = doc(db, "posts", categoryId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data() as Post;
  } else {
    throw new Error("post-not-found");
  }
};

type PostUpdateParams = {
  status: string;
};

const update = (postId: string, params: PostUpdateParams) => {
  const postRef = doc(db, "posts", postId);

  return updateDoc(postRef, params);
};

export const PostService = {
  getAllSuscription,
  getById,
  update,
};
